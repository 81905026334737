<script src="./snackbars.ts" lang="ts"></script>
<template>
    <div class="snackbar-list">
        <kwc-snackbar
            v-for="snack in list"
            :key="snack.id"
            :message="snack.message"
            :hasiconinfo="snack.hasiconinfo"
            :hasiconclosebtn="snack.hasiconclosebtn"
            :closingdelay="snack.closingdelay"
            :color="snack.color"
            @click="closeSnackbar(snack.id)"
        />
    </div>
</template>
<style lang="scss" src="./snackbars.scss" scoped></style>
