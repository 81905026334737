
import "@manyvids/kiwi-web-components";
import { snackbars } from "./components/snackbars";

export default {
    name: "App",
    components: {
        snackbars,
    },
};
