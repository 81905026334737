import { h, createApp } from "vue";
import singleSpaVue from "single-spa-vue";

import App from "./App.vue";
import { init } from "./init-sequence";

const vueLifecycles = singleSpaVue({
    createApp,
    appOptions: {
        render() {
            const that = this as any;
            return h(App, {
                // single-spa props are available on the "this" object. Forward them to your component as needed.
                // https://single-spa.js.org/docs/building-applications#lifecyle-props
                name: that.name,
                mountParcel: that.mountParcel,
                // singleSpa: that.singleSpa
            });
        },
    },
    handleInstance(app: any) {
        init(app, "/", undefined);
    },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
