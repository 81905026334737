type Command = {
    name: string;
    data: Record<string, any>;
};

type CommandResult = any;

type CommandHandler = (data: Record<string, any>) => CommandResult;

const commandHandlers: Record<string, CommandHandler> = {};

/**
 * Register the command name associated to the handler to execute.
 *
 * @param name Command name that will trigger the handler
 * @param handler Method to run when Command name has been called
 */
export function registerCommandHandler(name: string, handler: CommandHandler) {
    commandHandlers[name] = handler;
}

/**
 * Command interface stored in window.ManyVids.overlayUtils.
 */
const overlayUtilsGlobal = {
    /**
     * * MFE receiving commands from API.
     * * Executes a handler associated to the name of the command requested.
     *
     * @param command.name Name of the command to execute
     * @param command.data Parameters that needs to be used by handler
     * @returns Results of the handler with parameters
     *
     * @throws
     * The command name is not associated with a function.
     */
    sendCommand(command: Command): CommandResult {
        const handler = commandHandlers[command.name];
        if (typeof handler !== "function") {
            throw new Error(
                `Invalid kiwi-overlay-utils command: ${command.name}`
            );
        }
        return handler(command.data);
    },
};

const globalWindow = window as any;
const manyVidsGlobal = globalWindow.ManyVids || (globalWindow.ManyVids = {});

manyVidsGlobal.overlayUtils = overlayUtilsGlobal;
