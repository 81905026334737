import { defineComponent } from "vue";
import { registerCommandHandler } from "@/interface";

interface ISnackbar {
    id: number;
    message: string;
    hasiconinfo: boolean;
    hasiconclosebtn: boolean;
    closingdelay: number;
    color: string;
}

export default defineComponent({
    name: "Snackbars",
    data() {
        return {
            list: [] as ISnackbar[],
        };
    },
    mounted() {
        registerCommandHandler("show-snackbar", (data) => {
            const { snackbar } = data as { snackbar: ISnackbar };
            this.showSnackbar(snackbar);
        });
        registerCommandHandler("close-snackbar", (data) => {
            const { id } = data as { id: number };
            this.closeSnackbar(id);
        });
        registerCommandHandler("link-copied", (data) => {
            const { snackbar } = data as { snackbar: ISnackbar };
            this.showSnackbar(snackbar);
        });
    },
    methods: {
        showSnackbar(snackbar: ISnackbar) {
            this.list.push(snackbar);
            const closingdelay = snackbar.closingdelay || 3000;

            window.setTimeout(() => {
                this.closeSnackbar(snackbar.id);
            }, closingdelay);
        },
        closeSnackbar(id: number) {
            const index = this.list.findIndex((snackbar) => snackbar.id === id);
            if (index >= 0) {
                this.list.splice(index, 1);
            }
        },
    },
});
